import React from 'react'
import InviteButton from './invite'

function ClickUpInviteButton() {
  return (
    <InviteButton name="ClickUp" apiEndpoint="/setup/clickup">
      Get Invitation to ClickUp
    </InviteButton>
  )
}

export default ClickUpInviteButton
