import React from 'react'
import styled from '@emotion/styled'
import UnstyledButton from '../buttons/button'

const Wrapper = styled.div`
  margin: 80px -50px;
  padding: 50px 100px;
  box-shadow: 2px 2px 6px 0 rgba(117,176,156,0.3);
  background: #1E342C;
  color: white;
  text-align: center;
`

const Headline = styled.h2`
  margin-top: 0;
  margin-bottom: 14px;
`

const Text = styled.div`
  margin-bottom: 28px;
`

const ButtonList = styled.div`
  display: flex;
  justify-content: center;
`

const Button = styled(UnstyledButton)`
  margin: 0 10px;
`

export default function PaymentBox({ product, coupons: couponsString, prices: pricesString, title, text }) {
  const coupons = couponsString.split(',')
  const prices = pricesString.split(',')

  const showCheckout = (coupon) => {
    // eslint-disable-next-line no-undef
    Paddle.Checkout.open({
      product,
      coupon,
      allowQuantity: false,
    })
  }

  return (
    <Wrapper>
      <Headline>{title}</Headline>
      <Text>{text}</Text>
      <ButtonList>
        {
          prices.map((price, index) => (
            <Button onClick={() => showCheckout(coupons[index])}>
              {price}
            </Button>
          ))
        }
      </ButtonList>
    </Wrapper>
  )
}