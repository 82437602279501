import React from "react"
import { graphql, Link } from "gatsby"
import styled from "@emotion/styled"
import rehypeReact from "rehype-react"
import Container from "../components/container"
import ZeplinInviteButton from "../components/buttons/zeplinInvite"
import ClickUpInviteButton from "../components/buttons/clickUpInvite"
import GitHubInviteButton from "../components/buttons/githubInvite"
import RestrictedPage from "../components/restrictedPage"
import SideNavigation from "../components/sideNavigation"
import RestrictedGithubInviteButton from "../components/markdown/restrictedGithubInvite"
import TwitterShareBox from "../components/markdown/twitterShareBox"
import PaymentBox from "../components/markdown/paymentBox"
import Button from "../components/markdown/button"
import InstructorImage from "../components/instructorImage"
import MailerliteBox from "../components/markdown/mailerliteBox"
import SEO from "../components/seo"

const StyledContainer = styled(Container)`
  h2 {
    font-size: 30px;
    line-height: 36px;
  }

  h3 {
    font-size: 24px;
    line-height: 30px;
  }

  blockquote {
    font-style: italic;
    background: #eee;
    padding: 1em 40px;
    margin: 0;
  }

  pre {
    background: #222;
    color: #eee;
    padding: 10px;
    margin: 50px auto;
    overflow-x: auto;
  }

  pre code {
    background: none;
    padding: 0;
    border-radius: 0;
  }

  code {
    background: rgba(238, 238, 170, 0.4);
    padding: 3px 6px;
    border-radius: 4px;
  }

  hr {
    margin: 30px 0;
    opacity: 0;
  }

  .gatsby-resp-image-wrapper {
    margin: 50px auto;
    box-shadow: 0px 2px 6px 0px rgba(0,0,0, 0.2);
  }
`

const Title = styled.h1`
  font-size: 44px;
  line-height: 50px;
  margin-bottom: 0;
`

const Subtitle = styled.h2`
  margin-top: 0;
`

const Section = styled.div`
	margin-bottom: 40px;
	font-size: 16px;
`

const LineWrapper = styled.div`
  background: ${(props) => props.highlight ? 'rgba(104, 247, 142, 0.2)' : 'none'};
`

function Line({ children }) {
  const text = children.replace(/^\+/, '')
  const highlight = children.startsWith('+')
  return (
    <LineWrapper highlight={highlight}>
      {text.length > 0 ? text : ' '}
    </LineWrapper>
  )
}

function Code(props) {
  let lines = props.children[0].split('\n')
  if (lines[lines.length - 1] === '') {
    lines = lines.slice(0, -1)
  }

  if (lines.length === 1) {
    return <code>{props.children}</code>
  }

  return (
    <code>
      {lines.map((line) => (
        <Line>{line}</Line>
      ))}
    </code>
  )
}

export default function Template({ data, pageContext }) {
  const { currentPage, nextPage } = data

  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: {
      "zeplin-invite-button": ZeplinInviteButton,
      "clickup-invite-button": ClickUpInviteButton,
      "github-invite-button": GitHubInviteButton,
      "github-invite-button-restricted": RestrictedGithubInviteButton,
      "twitter-share-box": TwitterShareBox,
      "mailerlite-box": MailerliteBox,
      "payment-box": PaymentBox,
      "instructor-image": InstructorImage,
      "button": Button,
      code: Code,
    },
  }).Compiler

  return (
    <RestrictedPage restricted={!currentPage.frontmatter.public}>
      {
        !currentPage.frontmatter.hideNavigation && <SideNavigation sections={pageContext.navigation} />
      }
      <SEO
        title={currentPage.frontmatter.pageTitle || currentPage.frontmatter.title}
        description={currentPage.frontmatter.pageDescription}
        image={currentPage.frontmatter.socialCard}
      />
      <StyledContainer>
        <Title>{currentPage.frontmatter.title}</Title>
        <Section>{currentPage.frontmatter.section}</Section>
        {
          currentPage.frontmatter.subtitle && (
            <Subtitle>{currentPage.frontmatter.subtitle}</Subtitle>
          )
        }
        {renderAst(currentPage.htmlAst)}
        {
          currentPage.frontmatter.next && !currentPage.frontmatter.hideNavigation && (
            <>
              <hr />
              <p>
                <b>Next: <Link to={nextPage.frontmatter.slug}>{nextPage.frontmatter.title}</Link></b>
              </p>
            </>
          )
        }
      </StyledContainer>
    </RestrictedPage>
  )
}

export const pageQuery = graphql`
  query($id: String!, $next: String) {
    currentPage: markdownRemark(id: { eq: $id }) {
      htmlAst
      frontmatter {
        title
        subtitle
        pageTitle
        pageDescription
        socialCard
        section
        next
        public
        hideNavigation
      }
    }
    nextPage: markdownRemark(frontmatter: { slug: { eq: $next } }) {
      frontmatter {
        title
        slug
      }
    }
  }
`