import React from 'react'
import InviteButton from './invite'

function getErrorMessage(error) {
  if (error.message.includes('Could not clone: Name already exists')) {
    return <>Your repo has already been created. Please <a target="__blank" href={`${error.repoUrl}/invitations`}>click here</a> and accept the invitation.</>
  }
  console.error(error.message)
  return 'An unknown error occured. Please try again or contact me via Slack or johannes@ooloo.io'
}

function getMessage({ isLoading, error, data }) {
  if (isLoading) {
    return 'Setting up a repository for you. This may take surprisingly long.'
  }
  if (error) {
    return getErrorMessage(error)
  }
  if (data) {
    return <>Successfully created your repository. Please <a target="__blank" href={`${data.repoUrl}/invitations`}>click here</a> and accept the invitation.</>
  }
  return null
}

function GithubInviteButton({ project }) {
  return (
    <InviteButton
      name="GitHub"
      apiEndpoint={`/setup/github?project=${project}`}
      getMessage={getMessage}
    >
      Set up GitHub repository
    </InviteButton>
  )
}

export default GithubInviteButton
