import React from 'react'
import InviteButton from './invite'

function ZeplinInviteButton() {
  return (
    <InviteButton name="Zeplin" apiEndpoint="/setup/zeplin">
      Get Invitation to Zeplin
    </InviteButton>
  )
}

export default ZeplinInviteButton
