import React, { useState } from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

const Container = styled.div`
  display: ${props => props.collapsed ? 'none' : 'block'};
  min-width: 200px;
  max-width: 308px;
  padding: 75px 20px;
  text-align: right;
  position: absolute;
  border-right: 1px solid #eee;
  font-size: 16px;
  background: white;

  @media(min-width: 901px) {
    display: block;
  }

  @media(max-width: 1700px) {
    position: relative;
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  :first-of-type{
    font-size: 22px;
    font-weight: bold;
  }
`

const SectionTitle = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const PageLink = styled(Link)`
  color: black;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Wrapper = styled.div`
  position: relative;
`

const CollapseButton = styled.button`
  width: 34px;
  height: 34px;
  position: absolute;
  top: 10px;
  ${props => props.collapsed ? 'left: 10px;' : 'right: 10px'};
  z-index: 1;
  border: 1px solid;
  border-radius: 50%;
  background: none;

  @media(min-width: 900px) {
    display: none;
  }
`

export default function SideNavigation({ sections }) {
  const [collapsed, setCollapsed] = useState(typeof localStorage === 'undefined' ? false : localStorage.getItem('navigationCollapsed') === 'true');
  const toggleCollapsed = () => {
    localStorage.setItem('navigationCollapsed', !collapsed);
    setCollapsed((current) => !current);
  }

  return (
    <Wrapper>
      <CollapseButton
        onClick={toggleCollapsed}
        collapsed={collapsed}
      >
        {collapsed ? '>' : '<'}
      </CollapseButton>
      <Container collapsed={collapsed}>
        {
          sections.map((section) => (
            <Section key={section.title}>
              <SectionTitle>{section.title}</SectionTitle>

              {section.pages.map(({ title, slug }) => (
                <PageLink
                  key={slug}
                  to={slug}
                  activeStyle={{
                    color: "#75b09c",
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                >
                  {title}
                </PageLink>
              ))}
            </Section>
          ))
        }
      </Container>
    </Wrapper>
  )
}
