import React from 'react'
import styled from '@emotion/styled'
import UnstyledButton from '../buttons/button'
import TwitterIcon from '../../icons/twitter-white.svg'

const Wrapper = styled.div`
  margin: 80px -50px;
  padding: 20px 100px;
  box-shadow: 2px 2px 6px 0 rgba(117,176,156,0.3);
  background: #1E342C;
  color: #eee;
  text-align: center;

  @media(max-width: 900px) {
    margin: 40px -20px;
    padding: 10px;
  }
`

const Headline = styled.h2`
  margin-top: 30px;
  margin-bottom: 14px;
`

const Text = styled.div`
  margin-bottom: 28px;
`

const Button = styled(UnstyledButton)`
  background: #1DA1F2;
  display: flex;
  align-items: center;
  margin: ${props => props.simple ? '50px' : '30px'} auto;

  @media(max-width: 600px) {
    font-size: 16px;
  }
`

const Icon = styled.img`
  margin-left: 10px;

  @media(max-width: 600px) {
    width: 16px;
  }
`

export default function TwitterShareBox({ text, url, hashtags, simple }) {
  const onClick = () => {
    let twitterUrl = `https://twitter.com/share?text=${text}&url=${url}&related=j_kettmann`
    if (hashtags) {
      twitterUrl += `&hashtags=${hashtags}`
    }
    window.open(twitterUrl, 'twitter-share', 'width=550,height=235');
  }

  if (simple) {
    return (
      <Button onClick={onClick} simple={simple}>
        Spread the word <Icon src={TwitterIcon} />
      </Button>
    )
  }
  return (
    <Wrapper>
      <Headline>Could you help me out?</Headline>
      <Text>If you enjoy this course so far I'd be super grateful if you could share it with your friends.</Text>
      <Button onClick={onClick}>
        Spread the word <Icon src={TwitterIcon} />
      </Button>
    </Wrapper>
  )
}