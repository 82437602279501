import React, { useState } from 'react'
import Button from './button'
import sendApiRequest from '../../sendApiRequest'
import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 30px 0;
`

const Message = styled.div`
  width: 100%;
  margin-top: 8px;
  font-size: 16px;
  text-align: center;
`

function getDefaultMessage({ status, name }) {
  if (status === 'error') {
    return 'An error occured. Please try again or contact me via Slack or johannes@ooloo.io'
  }
  if (status === 'success') {
     return `Successfully invited to ${name}`
  }
  if (status === 'loading') {
     return `Sending out invititation to ${name}...`
  }
  return null
}

function InviteButton({ name, apiEndpoint, getMessage = getDefaultMessage, children }) {
  const [status, setStatus] = useState('active')
  const [responseData, setResponseData] = useState(null)
  const [error, setError] = useState(null)

  const onClick = async () => {
    setStatus('loading')
    try {
      const response = await sendApiRequest(apiEndpoint)
      setResponseData(response.data)
      setStatus('success')
    } catch (error) {
      console.error(error.response.data)
      setError(error.response.data)
      setStatus('error')
    }
  }

  const message = getMessage({
    status,
    name,
    data: status === 'success' ? responseData : null,
    error: status === 'error' ? error : null,
    isLoading: status === 'loading',
  })

  const disabled = status === 'loading' || status === 'success'
  return (
    <Container>
      <Button onClick={onClick} disabled={disabled}>
        {children}
      </Button>

      {
        message && (
          <Message>
            {message}
          </Message>
        )
      }
    </Container>
  )
}

export default InviteButton
