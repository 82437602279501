import React from 'react'
import styled from '@emotion/styled'
import UnstyledButton from '../buttons/button'

const Wrapper = styled.div`
  margin: 80px 0;
  display: flex;
  justify-content: center;
`

const Button = styled(UnstyledButton)`
  display: block;
  text-align: center;
`

export default function MarkdownButton(props) {
  return (
    <Wrapper>
      <Button as="a" {...props} />
    </Wrapper>
  )
}
