import React, { useEffect } from 'react';
import styled from "@emotion/styled"

const Wrapper = styled.div`
  margin: 80px -50px;
  padding: 20px 100px;
  box-shadow: 2px 2px 6px 0 rgba(117,176,156,0.3);
  background: #1E342C;
  color: #eee;
  text-align: center;

  @media(max-width: 900px) {
    margin: 40px -20px;
    padding: 10px;
  }

  h4 {
    font-size: 34px !important;
    line-height: 42px !important;
    margin-bottom: 10px !important;
    font-family: 'Raleway', sans-serif !important;
    font-weight: 700 !important;
  }

  p {
    margin-bottom: 40px !important;
    font-family: 'Lato', sans-serif !important;
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 30px !important;
  }

  .embedForm {
    background: none !important;
  }

  .ml-form-embedContent {
    margin: 0 !important;
  }
`

const MailerliteBox = React.memo(({ form }) => {
  useEffect(() => {
    window.MailerLiteObject = 'ml';
    const script = document.createElement('script');
    script.async = 1;
    script.src = 'https://static.mailerlite.com/js/universal.js?v'+(~~(new Date().getTime()/1000000));
    script.onload = () => window.ml('accounts', '1382888', 'c4v2p8y4b0', 'load');
    document.head.appendChild(script);
  }, []);

  return (
    <Wrapper>
      <div
        className="ml-form-embed"
        data-account="1382888:c4v2p8y4b0"
        data-form={form}
      />
    </Wrapper>
  )
})

export default MailerliteBox
