import React from "react"
import styled from "@emotion/styled"
import { useLocation } from "@reach/router"
import Container from "../container"
import GithubLoginButton from "../buttons/githubLogin"
import GithubInviteButton from "../buttons/githubInvite"
import useAuthentication from '../useAuthentication'

const AuthContainer = styled(Container)`
  display: flex;
  justify-content: center;
  padding: 50px 0;
`

export default function RestrictedGithubInviteButton({ project }) {
  const location = useLocation()
  const { isPending, isUnauthorized } = useAuthentication()

  if (isPending) {
    return (
      <AuthContainer>
        Loading
      </AuthContainer>
    )
  }

  if (isUnauthorized) {
    return (
      <AuthContainer>
        <GithubLoginButton redirect={location.pathname} />
      </AuthContainer>
    )
  }

  return (
    <GithubInviteButton project={project} />
  )
}
